<template>
	<div>
		<a-modal title="查看团员" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div class="flex wrap" v-if="datas.length>0">
					<div class="look-group-member-item flex alcenter space" v-for="(item,index) in datas">
						<div class="flex alcenter">
							<img v-if="item.member_face !== null" :src="item.member_face" class="look-group-member-item-face"/>
							<img v-else src="../../../../../../assets/image/woyaoyou_head_defult@2x.png" class="look-group-member-item-face"/>
							<div class="ml15 ft14 ftw400 text-over4">{{item.member_name}}</div>
						</div>
						<div class=" ft14 ftw400 text-over4 cl-red">{{item.status_means}}</div>
					</div>
				</div>
				
				<div v-else class="flex center">
					<a-empty/>
				</div>
				
				<div class="flex center mt20">
					<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../../common/mixin/list.js';
	export default{
		mixins:[listMixin],
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			pin_tuan_group_id:{
				type:Number,
				default:0,
			}
		},
		data(){
			return{
				loading:false,
				pagination:{
					total:0,
					current:1,
					pageSize:4,
				},
				datas:[]
			}
		},
		created() {
			this.getLists();
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getGroupMemberList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					pin_tuan_group_id:this.pin_tuan_group_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleCancel(){
				this.$emit('cancel');
			}
		}
	}
</script>

<style>
	.look-group-member-item{
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		padding: 16px;
		margin-bottom: 10px;
	}
	
	.look-group-member-item-face{
		width: 48px;
		height: 48px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}
</style>
