<template>
	<div>
		<a-modal title="设为成团" :width="480" :visible="visible" @cancel="handleCancel" >
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定成团
				</a-button>
			</template>
			
			<div>
				<a-spin :spinning="loading">
					<div class="to-group-condition">还差{{remain_num}}人成团</div>
					
					<div class="mt30">
						<div class="flex alcenter">
							<div class="ft14 ftw400 cl-info">添加团员</div>
							<div class="ft12 ftw400 cl-notice ml8">头像建议上传100x100px</div>
						</div>
						
						<div class="mt20">
							<div v-for="(item,index) in datas" class="flex alcenter center">
								<upload-img :typeClass="'small'" v-model="item.member_face" ></upload-img>
								<div class="ml20" style="width: 100%;">
									<a-input v-model="item.member_name" placeholder="请输入昵称"/>
								</div>
							</div>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../../components/upload/upload.vue';
	export default{
		components: {
			uploadImg,
		},
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			pin_tuan_group_id:{
				type:Number,
				default:0,
			}
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				datas:[],
				remain_num:0,
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getGroupDetail',{
					pin_tuan_group_id:this.pin_tuan_group_id,
				}).then(res=>{
					this.remain_num=res.info.remain_num;
					this.getDatas();
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			getDatas(){
				for(var i=0; i<this.remain_num; i++){
					this.datas.push(
						{member_face:"",member_name:""}
					)
				}
			},
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/toGroup',{
					pin_tuan_group_id:this.pin_tuan_group_id,
					datas:this.datas,
				}).then(res=>{
					this.confirmLoading=false;
					this.$message.success('操作成功',1,()=>{
						this.$emit('ok');
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			handleCancel(){
				this.$emit('cancel');
			}
		}
	}
</script>

<style>
	.to-group-condition{
		width: 100%;
		height: 50px;
		background: #F5F6FA;
		border-radius: 4px;
		line-height: 50px;
		text-align: center;
		font-size: 14px;
		font-weight: 600;
		color: #4772FF;
	}
</style>
